<template>
  <div>
    <b-modal
      ref="select-parameter"
      id="select-parameter-modal"
      :title="$t('views.parameters.title')"
    >
      <b-table
        :fields="fields"
        :items="values"
        outlined
        small
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      >
        <template v-slot:cell(value)="row">
          <b-row>
            <b-col>
              <input
                type="number"
                min="1"
                class="rightBlock"
                step="0.1"
                max="9999999"
                v-if="
                  row.item.idParameter == 0 &&
                  item.Parameters.some(
                    (x) => x.idParameter == row.item.idParameter
                  )
                "
                :number="true"
                :locale="$i18n.locale"
                v-model="row.item.value"
                @change="changedValue(true, row.item)"
              />
            </b-col>
            <b-col>
              <b-checkbox
                :checked="
                  item.Parameters.some(
                    (x) => x.idParameter == row.item.idParameter
                  )
                "
                class="rightBlock"
                block
                size="sm"
                @change="changedValue($event, row.item)"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button
            @click="$bvModal.hide('select-parameter-modal')"
            :disabled="okLoading"
            >{{ $t("general.cancel") }}</b-button
          ><b-button
            variant="primary"
            @click="$bvModal.hide('select-parameter-modal')"
            :disabled="okLoading"
            >{{ $t("general.ok") }}</b-button
          ></slot
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("Subcases", ["parameters"]),
    fields() {
      return [
        {
          key: "idParameter",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Id"),
        },
        {
          key: "parameterName",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Name"),
        },
        {
          key: "value",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Value"),
        },
      ];
    },
    values() {
      if (!this.parameters) return [];
      var customParam = this.item.Parameters.find((x) => x.idParameter == 0);
      if (!customParam) {
        customParam = {
          idParameter: 0,
          parameterName: "Custom",
          value: 1,
        };
      }
      return this.parameters.concat([customParam]);
    },
  },
  methods: {
    changedValue(event, selected) {
      this.$emit("changed", event, selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

columnSize {
  max-width: 150px;
}
</style>
