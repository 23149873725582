<template>
  <b-card class="h-100" no-body>
    <b-card-header header-border-variant="light" class="pb-0">
      <form>
        <b-row class="mb-3">
          <b-col>
            <div>
              <header-input
                v-if="editMode"
                type="text"
                class="form-control"
                :placeholder="$t('views.subCases.nameSubcase')"
                :label="$t('views.subCases.nameSubcase')"
                @input="updateSubcasename"
                :value="subcaseName"
                required
              />
              <div class="invalid-feedback">Please choose a username.</div>
              <h3 v-if="!editMode">{{ subcaseName }}</h3>
            </div>
          </b-col>
          <b-col>
            <b-button-toolbar>
              <b-button-group class="ml-auto">
                <back-button />
                <b-button
                  v-if="editMode && !subcaseId.newCase"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.viewMode')"
                  @click="SaveCase(false)"
                >
                  <view-icon />
                </b-button>
                <b-button
                  v-if="editMode"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.save')"
                  @click="SaveCase(true)"
                >
                  <font-awesome-icon id="disabled-wrapper" icon="save" />
                </b-button>
                <b-button
                  v-if="!editMode"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.subCases.buttons.edit')"
                  @click="OnEdit()"
                >
                  <b-icon-pencil />
                </b-button>
                <export-option
                  v-if="!editMode"
                  :idCalculation="this.$route.params.idSubcase"
                  :isPartial="true"
                />
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
      </form>
      <b-row>
        <b-col>
          <span class="font-weight-bold">
            {{ $t("views.subCases.NameCase") }}:
          </span>
          {{ caseName }}</b-col
        >
        <b-col>
          <label class="mr-4 lg font-weight-bold" for="StudyTypeSelection"
            >{{ $t("views.subCases.sections.StudyTypeSelection.title") }}
          </label>
          <span>
            {{ items.StudyType != null ? items.StudyType.sumlageName : "" }}
          </span>
        </b-col>
        <b-col class="col-1">
          <b-checkbox
            class="rightBlock"
            v-model="items.External"
            name="check-button"
            switch
          >
            <p v-if="items.External">{{ $t("general.external") }}</p>
            <p v-else>{{ $t("general.internal") }}</p>
          </b-checkbox>
        </b-col>
      </b-row>
      <b-row v-if="allowPerPatients !== null">
        <b-col>
          <b-row>
            <b-col class="col-5">
              <label align-v="center" class="font-weight-bold" for="numPatients"
                >{{
                  $t("views.subCases.sections.StudyTypeSelection.numPatients")
                }}: </label
              ><span v-if="!editMode" class="text-left ml-2">{{
                numPatients
              }}</span>
            </b-col>
            <b-col v-if="editMode">
              <b-form-group
                id="fieldset-1"
                label-for="numPatients"
                :invalid-feedback="$t('views.subCases.numPatientsInvalid')"
                :state="!isNumPatientsInvalid"
              >
                <b-input
                  type="number"
                  :number="true"
                  id="numPatients"
                  min="0"
                  v-model="numPatients"
                  :state="!isNumPatientsInvalid"
                  @change="UpdateAll"
                ></b-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="ml-4">
          <b-row>
            {{
              allowPerPatients
                ? $t("partialCostCalculation.perPatientOptions.1")
                : $t("partialCostCalculation.perPatientOptions.2")
            }}
          </b-row>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="p-0 overflow-auto">
      <b-tabs card fill class="h-100" content-class="h-100 p-0 ">
        <b-tab :title="$t('views.subCases.case')" class="h-100 p-0">
          <div v-if="load" class="text-center my-2">
            <simple-loader />
          </div>
          <b-card-text v-else class="h-100 p-0">
            <b-card no-body class="h-100" border-variant="light">
              <b-card-body class="h-100 p-0">
                <b-tabs pills card vertical class="h-100" content-class="h-100">
                  <ParametersPanel
                    :editMode="editMode"
                    @changedValue="UpdateAll"
                  />
                  <TimeCategoriesPanel
                    :editMode="editMode"
                    :timeCategories="items.TimeCategories"
                    @additem="addItem"
                  /><MainCategoriesPanel
                    :editMode="editMode"
                    :mainCategories="items.MainCategories"
                    :resources="items.ResourcePool.Resources"
                    :external="items.External"
                    @additem="addItem"
                    @insertitem="insertItem"
                    @resourcechanged="onResourceChange"
                    @hourschanged="onChangeHours"
                    @showmessage="ShowMessage"
                    @selectedItem="selectItemParameters"
                  />
                  <CostCategoriesSection
                    :editMode="editMode"
                    :costCategories="items.CostCategories"
                    :external="items.External"
                    :isPerPatient="isPerPatient"
                    :numPatients="numPatients"
                    :calculation="calculation"
                    @RecalculateItem="recalculateCostItem"
                    @RecalculateCategory="recalculateCostCategory"
                    @additem="addItem"
                    @selectedItem="selectItemParameters"
                  />
                  <SummationsPanel
                    :summations="items.Summations"
                    :external="items.External"
                  />
                </b-tabs>
              </b-card-body>
            </b-card>
          </b-card-text>
        </b-tab>
        <ResourcePool
          :resources="items.ResourcePool.Resources"
          :editMode="editMode"
          :duration="monthDurationCase"
          @updateAllResources="UpdateAllResources($event)"
        />
      </b-tabs>
    </b-card-body>
    <WarningModal
      :idSubcase="$route.params.idSubcase"
      :info="this"
      :name="subcaseName"
      :state="State"
      :show="showWarningModal"
      @edit="OpenEditionMode"
    />
    <close-warning-modal
      :ref="closeWarningRef"
      :isActive="showClosingWarning"
      @onClosing="cancelAutoSave"
      @onSaveAndExit="exitAndSaveCase"
      @onExitWithoutSaving="ExitNotSaving"
    />
    <SelectParameters :item="selectedItem" @changed="changedParameter" />
    <b-modal
      v-model="loading"
      title="Loading view-mode"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
    >
      <div class="d-block text-center">
        <h5>
          {{ $t("messages.subcaseViewMode") }}
        </h5>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../router";
import WarningModal from "@/components/StateWarning";
import ResourcePool from "@/components/ResourcePool";
import TimeCategoriesPanel from "@/components/TimeCategoriesPanel";
import MainCategoriesPanel from "@/components/MainCategoriesPanel";
import CostCategoriesSection from "@/components/cost-calculation/partial-document/costs-section";
import SummationsPanel from "@/components/SummationsPanel";
import headerinput from "@/components/header-input";
import ParametersPanel from "@/components/Subcase/ParametersPanelView.vue";
import SelectParameters from "@/components/Subcase/SelectParameter";
import ViewIcon from "@/components/general/icons/View.vue";
import ExportOption from "@/components/cost-calculation/export-option";
import CloseWarningModal from "@/components/general/close-warning-modal.vue";

Number.prototype.round = function (places) {
  return +(Math.round(this + "e+" + places) + "e-" + places);
};

export default {
  components: {
    WarningModal,
    TimeCategoriesPanel,
    MainCategoriesPanel,
    CostCategoriesSection,
    SummationsPanel,
    "header-input": headerinput,
    ParametersPanel,
    SelectParameters,
    "view-icon": ViewIcon,
    ExportOption,
    ResourcePool,
    CloseWarningModal,
  },
  data() {
    return {
      subcaseId: {},
      items: {
        Name: "",
        MainCategories: [],
        CostCategories: [],
        TimeCategories: [],
        ResourcePool: { Resources: [] },
      },
      editMode: false,
      showExport: false,
      showWarningModal: false,
      load: false,
      isPerPatient: false,
      numPatients: 0,
      monthDurationCase: 0,
      selectedItem: {
        Parameters: [],
      },
      calculation: false,
      timer: null,
      loading: false,

      closeWarningRef: "close-warning",
      caseNameRegex: /\/|\*|\?|\[|\]|:|\|/g,
    };
  },
  methods: {
    ...mapActions("Subcases", [
      "GetSubcase",
      "PutSubcase",
      "GetTemplate",
      "PostSubcase",
      "GetSalaryTypes",
      "GetSalaryInfo",
      "GetSubcaseState",
      "GetStudyTypes",
      "GetSurcharges",
      "putParameters",
    ]),
    ShowMessage(title, message, variant) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 4000,
        variant: variant,
        appendToast: true,
      });
    },
    onResourceChange(event, resourceInfo, taskInfo, structureInfo, main) {
      resourceInfo.Resource = event;
      this.onChangeHours(
        resourceInfo.Hours,
        resourceInfo,
        taskInfo,
        structureInfo,
        main
      );
    },
    onChangeHours(event, resourceInfo, taskInfo, structureInfo, mainCategory) {
      resourceInfo.Cost = resourceInfo.Resource.HourlyRate * event;
      resourceInfo.ExternalCost = resourceInfo.Resource.ExternalRate * event;

      resourceInfo.Cost = resourceInfo.Cost.round(2);
      resourceInfo.ExternalCost = resourceInfo.ExternalCost.round(2);

      if (this.isPerPatient) {
        resourceInfo.FinalCost = resourceInfo.Cost * this.numPatients;
        resourceInfo.FinalExternalCost =
          resourceInfo.ExternalCost * this.numPatients;
      } else {
        resourceInfo.FinalCost = resourceInfo.Cost;
        resourceInfo.FinalExternalCost = resourceInfo.ExternalCost;
      }

      resourceInfo.FinalCost = resourceInfo.FinalCost.round(2);
      resourceInfo.FinalExternalCost = resourceInfo.FinalExternalCost.round(2);

      this.recalculateTask(taskInfo, structureInfo, mainCategory);
    },
    recalculateTask(taskInfo, structureInfo, mainCategory) {
      taskInfo.Hours = taskInfo.Resources.reduce((r, b) => r + b.Hours, 0);
      taskInfo.Cost = taskInfo.Resources.reduce((r, b) => r + b.FinalCost, 0);
      taskInfo.ExternalCost = taskInfo.Resources.reduce(
        (r, b) => r + b.FinalExternalCost,
        0
      );
      if (taskInfo.Parameters.length > 0) {
        taskInfo.Cost = taskInfo.Parameters.reduce(
          (r, b) => r * this.getParameterValue(b),
          taskInfo.Cost
        );
        taskInfo.ExternalCost = taskInfo.Parameters.reduce(
          (r, b) => r * this.getParameterValue(b),
          taskInfo.ExternalCost
        );

        taskInfo.Cost = taskInfo.Cost.round(2);
        taskInfo.ExternalCost = taskInfo.ExternalCost.round(2);
      }
      this.updateSummationsCost(
        taskInfo.Id,
        taskInfo.Cost,
        taskInfo.ExternalCost
      );
      this.recalculateStructure(structureInfo, mainCategory);
    },
    recalculateStructure(structureInfo, mainCategory) {
      structureInfo.Cost = structureInfo.Tasks.reduce((r, b) => r + b.Cost, 0);
      structureInfo.ExternalCost = structureInfo.Tasks.reduce(
        (r, b) => r + b.ExternalCost,
        0
      );

      structureInfo.Cost = structureInfo.Cost.round(2);
      structureInfo.ExternalCost = structureInfo.ExternalCost.round(2);

      this.updateSummationsCost(
        structureInfo.Id,
        structureInfo.Cost,
        structureInfo.ExternalCost
      );
      this.recalculateMainCategory(mainCategory);
    },
    recalculateMainCategory(mainCategory) {
      mainCategory.Cost = mainCategory.Structures.reduce(
        (r, b) => r + b.Cost,
        0
      );
      mainCategory.ExternalCost = mainCategory.Structures.reduce(
        (r, b) => r + b.ExternalCost,
        0
      );

      mainCategory.Cost = mainCategory.Cost.round(2);
      mainCategory.ExternalCost = mainCategory.ExternalCost.round(2);

      this.updateSummationsCost(
        mainCategory.Id,
        mainCategory.Cost,
        mainCategory.ExternalCost
      );
    },
    recalculateCostItem(itemInfo, costCategory) {
      this.updateSummationsCost(
        itemInfo.Id,
        itemInfo.FinalCost || 0,
        itemInfo.FinalExternalCost || 0
      );
      this.updateSummationsCost(
        costCategory.Id,
        costCategory.Cost,
        costCategory.ExternalCost
      );
    },
    recalculateCostCategory(costCategory) {
      this.updateSummationsCost(
        costCategory.Id,
        costCategory.Cost,
        costCategory.ExternalCost
      );
    },
    getParameterValue(parameter) {
      if (parameter.idParameter == 0)
        return parameter.value ? parameter.value : 1;
      const param = this.parameters.find(
        (x) => x.idParameter == parameter.idParameter
      );
      if (param) return param.value ? param.value : 1;

      return 1;
    },
    deleteEmptyNamedResources() {
      for (let i = 0; i < this.items.ResourcePool.Resources.length; i++) {
        if (this.items.ResourcePool.Resources[i].Name === "")
          this.items.ResourcePool.Resources.splice(i, 1);
      }
    },
    SaveCase(mode) {
      if (!this.subcaseName) {
        this.ShowMessage(
          "Warning",
          this.$t("views.subCases.missingName"),
          "warning"
        );
        return;
      }
      if (this.subcaseName.match(this.caseNameRegex)) {
        this.ShowMessage(
          "Warning",
          this.$t("views.cases.notAllowedSymbols"),
          "warning"
        );
        return;
      }
      if (this.isNumPatientsInvalid) {
        this.ShowMessage(
          "Warning",
          this.$t("views.subCases.numPatientsInvalid"),
          "warning"
        );
        return;
      }

      mode ? (this.loading = false) : (this.loading = true);

      this.deleteEmptyNamedResources();
      this.verifyExternalCost();

      return new Promise((resolve, reject) => {
        this.PutSubcase({
          session: this.$session,
          api: this.$api,
          idSubcase: this.$route.params.idSubcase,
          file: JSON.stringify(this.items),
          name: this.subcaseName,
          summaryFile: JSON.stringify(this.SummaryFile),
          patients: this.numPatients,
        })
          .then(() => {
            this.putParameters({
              api: this.$api,
              idSubcase: this.$route.params.idSubcase,
              parameters: this.items.Parameters,
            })
              .then(() => {
                this.ShowMessage("Success", "Subcase saved", "success");
                this.editMode = mode;

                if (!this.editMode)
                  this.$api
                    .PutSubcaseState(this.$route.params.idSubcase, 1)
                    .then(() => {
                      this.cancelAutoSave();
                      resolve(true);
                    });
                else resolve(true);
                this.loading = false;
              })
              .catch((error) => {
                this.loading = false;
                this.ShowMessage(
                  this.$t("messages.ErrorSaving"),
                  error.response.data.message,
                  "danger"
                );
              });
          })
          .catch((error) => {
            this.loading = false;
            this.ShowMessage(
              this.$t("messages.ErrorSaving"),
              error.response.data.message,
              "danger"
            );
            reject(false);
          });
      });
    },
    ExitNotSaving() {
      this.$api.PutSubcaseState(this.$route.params.idSubcase, 1).then(() => {
        this.cancelAutoSave();
        this.next();
      });
    },
    async openPrint() {
      await this.$api
        .axios({
          url:
            "/cases/export/subcase/" +
            this.$route.params.idSubcase +
            "?format=PDF",
          method: "GET",
          responseType: "blob",
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          let w = window.open(url);
          w.print();
        });
    },
    UpdateAllResources(ResourceInfo) {
      for (var mainCategory in this.items.MainCategories) {
        for (var structure in this.items.MainCategories[mainCategory]
          .Structures) {
          for (var task in this.items.MainCategories[mainCategory].Structures[
            structure
          ].Tasks) {
            for (var resource in this.items.MainCategories[mainCategory]
              .Structures[structure].Tasks[task].Resources) {
              if (
                this.items.MainCategories[mainCategory].Structures[structure]
                  .Tasks[task].Resources[resource].Resource.Id ===
                ResourceInfo.Id
              ) {
                this.items.MainCategories[mainCategory].Structures[
                  structure
                ].Tasks[task].Resources[resource].Resource = ResourceInfo;
                this.onChangeHours(
                  this.items.MainCategories[mainCategory].Structures[structure]
                    .Tasks[task].Resources[resource].Hours,
                  this.items.MainCategories[mainCategory].Structures[structure]
                    .Tasks[task].Resources[resource],
                  this.items.MainCategories[mainCategory].Structures[structure]
                    .Tasks[task],
                  this.items.MainCategories[mainCategory].Structures[structure],
                  this.items.MainCategories[mainCategory]
                );
              }
            }
          }
        }
      }
    },
    UpdateAll() {
      this.calculation = !this.calculation;
      for (var resIndex in this.items.ResourcePool.Resources) {
        var resource = this.items.ResourcePool.Resources[resIndex];
        this.UpdateAllResources(resource);
      }
      for (var costCategory in this.items.CostCategories) {
        var categoryInfo = this.items.CostCategories[costCategory];
        for (var costItem in categoryInfo.Items) {
          var itemInfo = categoryInfo.Items[costItem];
          this.recalculateCostItem(itemInfo, categoryInfo);
        }
        this.recalculateCostCategory(categoryInfo);
      }
    },
    updateSummationsCost(itemId, internalCost, externalCost) {
      for (var index in this.items.Summations) {
        var summation = this.items.Summations[index];
        var item = summation.Objects.find((object) => object.Id == itemId);
        if (item) {
          item.ExternalCost = externalCost;
          item.InternalCost = internalCost;

          summation.Cost = summation.Objects.reduce(
            (r, b) => r + b.InternalCost,
            0
          );

          if (summation.OnlyInternal) {
            summation.ExternalCost = summation.Cost;
          } else {
            summation.ExternalCost = summation.Objects.reduce(
              (r, b) => r + b.ExternalCost,
              0
            );
          }

          var surcharge;

          try {
            surcharge =
              this.items.StudyType.Surcharges.find(
                (x) => x.idSurcharge == summation.Surcharge.idSurcharge
              )?.value || 0;
          } catch {
            surcharge = 0;
          }

          var surchargeValue = surcharge ? surcharge / 100.0 : 0;

          if (!summation.OnlyPercentage) surchargeValue += 1;

          summation.Surcharge.value = surcharge;

          summation.FinalCost = summation.Cost * surchargeValue;

          summation.FinalExternalCost = summation.ExternalCost * surchargeValue;

          if (summation.PerPatient) {
            const patients = this.numPatients > 0 ? this.numPatients : 1;

            var extCost = summation.FinalExternalCost / patients;
            var intCost = summation.FinalCost / patients;

            if (extCost != summation.FinalExternalCost)
              summation.FinalExternalCost = extCost;

            if (intCost != summation.FinalCost) summation.FinalCost = intCost;
          }

          summation.FinalCost = summation.FinalCost.round(2);
          summation.FinalExternalCost = summation.FinalExternalCost.round(2);

          this.updateSummationsCost(
            summation.Id,
            summation.FinalCost,
            summation.FinalExternalCost
          );
        }
      }
    },
    OnEdit() {
      this.showWarningModal = false;

      if (this.State == 4) {
        this.$bvModal.msgBoxOk(this.$t("messages.CaseSubmittedCanNotEdited"), {
          centered: true,
        });
        return;
      }
      if (this.State == 1) {
        // Open
        this.OpenEditionMode();
      } else this.$bvModal.show("state-modal");
    },
    async onStudyTypeChange() {
      await this.GetSurcharges({
        session: this.$session,
        api: this.$api,
        idCondition: this.items.StudyType.idSurcharge,
      });
      this.Surcharges.push({
        idSurcharge: 0,
        surchargeName: "None",
        value: 0,
      });
      this.items.StudyType.Surcharges = this.Surcharges;
      this.UpdateAll();
    },
    verifyExternalCost() {
      for (var index in this.items.ResourcePool.Resources) {
        if (
          this.items.ResourcePool.Resources[index].HourlyRate >
          this.items.ResourcePool.Resources[index].ExternalRate
        ) {
          this.ShowMessage(
            "Warning",
            "External cost is less than internal cost.",
            "warning"
          );
          break;
        }
      }
    },
    OpenEditionMode() {
      this.editMode = true;
      this.$api.PutSubcaseState(this.$route.params.idSubcase, 3);

      // Auto save every 5 minutes
      this.timer = setInterval(this.autoSave, 300000);
    },
    addItem(array, newItem) {
      if (!array.some((x) => x.Id.toString() == newItem.Id.toString())) {
        array.push(newItem);
        this.UpdateAll();
      } else {
        this.ShowMessage(
          this.$t("views.master-form.messages.materialCostAlreadyExists.title"),
          this.$t("views.master-form.messages.materialCostAlreadyExists.text")
        );
      }
    },
    insertItem(array, newItem, index) {
      array.splice(index, 0, newItem);
    },
    exitAndSaveCase() {
      this.SaveCase(false)
        .then(() => {
          this.cancelAutoSave();
          this.next();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSubcasename(value) {
      this.$store.commit("Subcases/setName", value);
    },
    selectItemParameters(item) {
      this.selectedItem = item;
      this.$bvModal.show("select-parameter-modal");
    },
    changedParameter(selected, item) {
      this.selectedItem.Parameters = this.selectedItem.Parameters.filter(
        (x) => x.idParameter != item.idParameter
      );
      if (selected) {
        if (
          !this.selectedItem.Parameters.some(
            (x) => x.idParameter == item.idParameter
          )
        ) {
          item.value = parseFloat(item.value);
          this.selectedItem.Parameters.push(item);
        }
      }
      this.UpdateAll();
    },
    autoSave() {
      if (this.isNumPatientsInvalid) {
        return;
      }
      this.SaveCase(true);
    },
    cancelAutoSave() {
      this.loading = false;
      if (this.timer != null) {
        clearInterval(this.timer);
      }
    },
  },
  computed: {
    ...mapGetters("Subcases", [
      "subcaseFile",
      "templateFile",
      "IDCase",
      "SalaryList",
      "State",
      "Editor",
      "StudyTypes",
      "Surcharges",
      "SummaryFile",
      "Patients",
      "PerPatient",
      "Duration",
      "subcaseName",
      "caseName",
      "parameters",
      "allowPerPatients",
    ]),
    ...mapGetters("Subcases", ["parameters"]),
    showClosingWarning() {
      return !this.load && this.editMode;
    },
    isNumPatientsInvalid() {
      if (this.allowPerPatients === null) return false;
      return !this.numPatients || this.numPatients <= 0;
    },
  },
  async created() {
    try {
      this.load = true;

      this.GetStudyTypes({
        session: this.$session,
        api: this.$api,
        enabled: true,
      }).then(() => {
        this.GetSubcase({
          session: this.$session,
          api: this.$api,
          idSubcase: this.$route.params.idSubcase,
        })
          .then(async () => {
            //TODO: parche
            this.items = this.subcaseFile;

            this.numPatients = this.Patients;
            this.isPerPatient = this.PerPatient;
            this.monthDurationCase = this.Duration;

            await this.onStudyTypeChange();

            this.load = false;
          })
          .catch(() => {
            router.push({
              name: "CasesList",
            });
          });
      });
    } catch (error) {
      console.log(error);
    }
  },
  beforeRouteLeave: function (to, from, next) {
    this.next = next;
    this.$refs[this.closeWarningRef].onLeave(next);
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
