<template>
  <div>
    <b-tab :title="$t('fields.Summations.Summations')">
      <template #title>
        <span class="d-inline-block text-truncate" style="max-width: 150px">
          {{ $t("fields.Summations.Summations") }}
        </span>
      </template>
      <b-card-body class="p-0">
        <h3>{{ $t("fields.Summations.Summations") }}</h3>
        <b-table
          :fields="fields.Summations"
          :items="summations"
          outlined
          small
          select-mode="single"
          show-empty
          :emptyText="$t('placeholders.NoInformationAdded')"
        >
          <template #head()="data">
            <div class="input text-left">
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell(Surcharge)="row">
            <div
              v-if="row.item.Surcharge.idSurcharge !== 0"
              class="input text-left"
            >
              {{
                row.item.Surcharge.value
                  ? row.item.Surcharge.value + "%"
                  : " 0%"
              }}
            </div>
          </template>

          <template v-slot:cell(Cost)="row">
            <div
              v-if="
                !row.item.OnlyPercentage && row.item.Surcharge.idSurcharge !== 0
              "
              class="input text-left"
            >
              <i18n-n :value="row.item.Cost" format="currency" />
            </div>
          </template>

          <template v-slot:cell(ExternalCost)="row">
            <div
              v-if="
                !row.item.OnlyPercentage && row.item.Surcharge.idSurcharge !== 0
              "
              class="input text-left"
            >
              <i18n-n :value="row.item.ExternalCost" format="currency" />
            </div>
          </template>

          <template v-slot:cell(FinalCost)="row">
            <div class="input text-left">
              <i18n-n :value="row.item.FinalCost" format="currency" />
            </div>
          </template>

          <template v-slot:cell(FinalExternalCost)="row">
            <div class="input text-left">
              <i18n-n :value="row.item.FinalExternalCost" format="currency" />
            </div>
          </template> </b-table
      ></b-card-body>
    </b-tab>
  </div>
</template>
<script>
export default {
  props: {
    summations: {
      required: true,
      type: Array,
    },
    external: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    external() {},
  },
  computed: {
    fields() {
      return {
        Summations: [
          {
            key: "Name",
            label: this.$t("fields.Summations.Name"),
          },
          {
            key: "Surcharge",
            label: this.$t("fields.Summations.Value"),
          },
          {
            key: this.external ? "FinalExternalCost" : "FinalCost",
            label: this.$t("fields.Summations.Final"),
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
