<template>
  <b-modal
    :id="id"
    @ok="onExitWithoutSaving"
    :title="$t('messages.titles.ExitNotSaved')"
  >
    <div class="d-block text-center">
      <h5>{{ $t("messages.prompts.ExitNotSaved") }}</h5>
    </div>
    <template v-slot:modal-footer>
      <b-button size="sm" @click="onCancel">
        {{ $t("general.cancel") }}
      </b-button>
      <b-button size="sm" variant="success" @click="onSaveAndExit">
        {{ $t("buttons.SaveAndExist") }}
      </b-button>
      <b-button size="sm" variant="danger" @click="onExitWithoutSaving">
        {{ $t("buttons.discard") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: {
      required: false,
      type: String,
      default: "close-warning-modal",
    },
    isActive: {
      required: true,
      type: Boolean,
    },
  },
  watch: {},
  methods: {
    onCancel() {
      this.$bvModal.hide(this.id);
      this.$emit("onCancel");
    },
    onSaveAndExit() {
      this.$bvModal.hide(this.id);
      this.$emit("onSaveAndExit");
    },
    onExitWithoutSaving() {
      this.$bvModal.hide(this.id);
      this.$emit("onExitWithoutSaving");
    },
    onLeave(next) {
      if (this.isActive) {
        this.$bvModal.show(this.id);
        next(false);
      } else {
        this.$emit("onClosing");
        next();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
