<template>
  <div>
    <b-tab
      v-for="main in mainCategories"
      :key="main.Name"
      :title="main.Name"
      class="text-wrap bd-highlight"
    >
      <template #title>
        <span class="d-inline-block text-truncate" style="max-width: 150px">
          {{ main.Name }}
        </span>
      </template>
      <b-card-body class="p-0">
        <h3>{{ main.Name }}</h3>
        <h4>
          {{ "Cost: "
          }}<i18n-n
            :value="external ? main.ExternalCost : main.Cost"
            format="currency"
          />
        </h4>
        <b-table
          :fields="fields.MainCategories"
          :items="main.Structures"
          outlined
          fixed
          small
          select-mode="single"
          show-empty
          :emptyText="$t('placeholders.NoInformationAdded')"
          table-variant="primary"
        >
          <template v-slot:table-colgroup>
            <col />
            <col class="w-25" />
          </template>

          <template #head()="data">
            <div class="input text-left">
              {{ data.label }}
            </div>
          </template>

          <template v-slot:cell(Name)="row">
            <b-row>
              <b-col cols="1" class="pl-3">
                <b-button
                  v-b-toggle="row.item.Id"
                  size="sm"
                  variant="outline-dark"
                >
                  <span class="when-open">
                    <b-icon-chevron-down />
                  </span>
                  <span class="when-closed">
                    <b-icon-chevron-right />
                  </span>
                </b-button>
              </b-col>
              <b-col class="pl-0">
                <b-input
                  :disabled="row.item.Blocked"
                  v-if="editMode"
                  v-model="row.item.Name"
                />
                <div v-else>{{ row.item.Name }}</div>
              </b-col>
            </b-row>
          </template>

          <template v-if="!external" v-slot:cell(Cost)="row">
            <div class="input text-left">
              <i18n-n :value="row.value" format="currency" />
            </div>
            <b-button
              v-if="editMode && !row.item.Blocked"
              class="rightBlock"
              size="sm"
              @click="DeleteStructure(row.item, main.Structures)"
              variant="danger"
            >
              <b-icon-dash-square />
            </b-button>
          </template>

          <template v-if="external" v-slot:cell(ExternalCost)="row">
            <div class="input text-left">
              <i18n-n :value="row.value" format="currency" />
            </div>
            <b-button
              v-if="editMode && !row.item.Blocked"
              class="rightBlock"
              size="sm"
              @click="DeleteStructure(row.item, main.Structures)"
              variant="danger"
            >
              <b-icon-dash-square />
            </b-button>
          </template>

          <template v-slot:row-details="structureInfo">
            <div v-if="structureInfo.item.Tasks">
              <b-collapse :id="structureInfo.item.Id">
                <b-table
                  :fields="fields.TaskCategories"
                  :items="structureInfo.item.Tasks"
                  outlined
                  small
                  fixed
                  select-mode="single"
                  show-empty
                  table-variant="secondary"
                  :emptyText="$t('placeholders.NoInformationAdded')"
                  tbody-tr-class="custom-border"
                >
                  <template v-slot:table-colgroup>
                    <col />
                    <col class="w-25" />
                    <col class="w-25" />
                  </template>

                  <template #head()="data">
                    <div class="input text-left">
                      {{ data.label }}
                    </div>
                  </template>

                  <template v-if="editMode" v-slot:cell(Description)="row">
                    <b-row>
                      <b-button
                        class="ml-3 mb-2"
                        size="sm"
                        @click="InsertTask(structureInfo.item, row.index)"
                      >
                        <b-icon-plus-circle />
                        {{ $t("buttons.insertTask") }}</b-button
                      >
                    </b-row>
                    <b-textarea
                      :disabled="row.item.Blocked"
                      no-resize
                      :placeholder="$t('placeholders.TaskDescription')"
                      v-model="row.item.Description"
                    />
                  </template>

                  <template v-slot:cell(Parameters)="row">
                    <div v-if="row.item.Parameters.length !== 0">
                      {{ sumParameterValues(row.item.Parameters) }}
                    </div>
                    <div v-else>
                      {{ "---" }}
                    </div>
                  </template>

                  <template v-slot:row-details="taskInfo">
                    <div v-if="editMode">
                      <b-collapse :id="'comment' + taskInfo.item.Id">
                        <hr />
                        <b-textarea
                          :placeholder="$t('placeholders.Comment')"
                          no-resize
                          v-model="taskInfo.item.Comment"
                        />
                      </b-collapse>
                    </div>
                    <div v-else>
                      <hr />
                      <mark class="bg-warning" v-if="taskInfo.item.Comment">
                        {{ taskInfo.item.Comment }}
                      </mark>
                    </div>
                    <hr />
                    <b-table
                      :fields="fields.Resources"
                      :items="taskInfo.item.Resources"
                      outlined
                      bordered
                      small
                      fixed
                      select-mode="single"
                      show-empty
                      table-variant="light"
                      :emptyText="$t('placeholders.NoInformationAdded')"
                    >
                      <template v-slot:table-colgroup>
                        <col />
                        <col class="w-25" />
                        <col class="w-25" />
                      </template>

                      <template #head()="data">
                        <div class="input text-left">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell(Name)="row">
                        <template v-if="editMode">
                          <b-select
                            v-model="row.item.Resource"
                            @change="
                              $emit(
                                'resourcechanged',
                                $event,
                                row.item,
                                taskInfo.item,
                                structureInfo.item,
                                main
                              )
                            "
                          >
                            <b-select-option
                              v-bind:key="'previous'"
                              :value="row.item.Resource"
                            >
                              {{ row.item.Resource.Name }}
                            </b-select-option>

                            <b-select-option
                              v-for="(resource, index) in resources.filter(
                                (x) =>
                                  x.Id !== row.item.Resource.Id &&
                                  !taskInfo.item.Resources.some(
                                    (e) => x.Id == e.Resource.Id
                                  )
                              )"
                              v-bind:key="index"
                              :value="resource"
                            >
                              {{ resource.Name }}
                            </b-select-option>
                          </b-select>
                        </template>
                        <template v-else>
                          {{ row.item.Resource.Name }}
                        </template>
                      </template>
                      <template v-if="editMode" v-slot:cell(Hours)="row">
                        <b-input
                          v-model="row.item.Hours"
                          @change="
                            $emit(
                              'hourschanged',
                              $event,
                              row.item,
                              taskInfo.item,
                              structureInfo.item,
                              main
                            )
                          "
                          type="number"
                          min="0.00"
                          max="9999999"
                          :number="true"
                        />
                      </template>
                      <template v-if="!external" v-slot:cell(Cost)="row">
                        <div class="input text-left">
                          <i18n-n :value="row.value" format="currency" />
                        </div>
                      </template>
                      <template v-if="external" v-slot:cell(ExternalCost)="row">
                        <div class="input text-left">
                          <i18n-n :value="row.value" format="currency" />
                        </div>
                      </template>
                      <template v-if="!external" v-slot:cell(FinalCost)="row">
                        <div class="input text-left">
                          <i18n-n :value="row.value" format="currency" />
                        </div>
                        <b-button
                          v-if="editMode && !taskInfo.item.Blocked"
                          class="rightBlock"
                          size="sm"
                          @click="DeleteResource(row.item, taskInfo.item)"
                          variant="danger"
                        >
                          <b-icon-person-dash />
                        </b-button>
                      </template>

                      <template
                        v-if="external"
                        v-slot:cell(FinalExternalCost)="row"
                      >
                        <div class="input text-left">
                          <i18n-n :value="row.value" format="currency" />
                        </div>
                        <b-button
                          v-if="editMode && !taskInfo.item.Blocked"
                          class="rightBlock"
                          size="sm"
                          @click="DeleteResource(row.item, taskInfo.item)"
                          variant="danger"
                        >
                          <b-icon-person-dash />
                        </b-button>
                      </template>
                    </b-table>
                    <b-button
                      v-if="editMode"
                      size="sm"
                      @click="AddResource(taskInfo.item)"
                      variant="success"
                    >
                      <b-icon-person-plus />
                      {{ $t("buttons.AddResource") }}
                    </b-button>
                  </template>
                  <template v-if="!external" v-slot:cell(Cost)="row">
                    <b-row v-if="editMode" class="my-3"></b-row>
                    <div class="input text-left">
                      <i18n-n :value="row.value" format="currency" />
                    </div>
                    <b-button
                      v-if="editMode && !row.item.Blocked"
                      class="rightBlock"
                      size="sm"
                      @click="DeleteTask(row.item, structureInfo.item)"
                      variant="danger"
                    >
                      <b-icon-dash-square />
                    </b-button>
                    <b-button
                      v-b-toggle="'comment' + row.item.Id"
                      v-if="editMode"
                      v-b-tooltip.hover
                      size="sm"
                      variant="primary"
                      class="rightBlock"
                      :title="$t('views.subCases.buttons.comment')"
                      ><b-icon-chat-right-text
                    /></b-button>
                    <b-button
                      v-b-tooltip.hover
                      v-if="editMode"
                      size="sm"
                      variant="primary"
                      class="mr-1 rightBlock"
                      :title="$t('objects.parameters')"
                      @click="selectParameters(row.item)"
                      ><b-icon-sliders
                    /></b-button>
                  </template>

                  <template v-if="external" v-slot:cell(ExternalCost)="row">
                    <b-row v-if="editMode" class="my-3"></b-row>
                    <div class="input text-left">
                      <i18n-n :value="row.value" format="currency" />
                    </div>
                    <b-button
                      v-if="editMode && !row.item.Blocked"
                      class="rightBlock"
                      size="sm"
                      @click="DeleteTask(row.item, structureInfo.item)"
                      variant="danger"
                    >
                      <b-icon-dash-square />
                    </b-button>
                    <b-button
                      v-b-toggle="'comment' + row.item.Id"
                      v-if="editMode"
                      v-b-tooltip.hover
                      size="sm"
                      variant="primary"
                      class="rightBlock"
                      :title="$t('views.subCases.buttons.comment')"
                      ><b-icon-chat-right-text /></b-button
                    ><b-button
                      v-b-tooltip.hover
                      v-if="editMode"
                      size="sm"
                      variant="primary"
                      class="mr-1 rightBlock"
                      :title="$t('objects.parameters')"
                      @click="selectParameters(row.item)"
                      ><b-icon-sliders
                    /></b-button>
                  </template>
                </b-table>
                <b-button
                  v-if="editMode"
                  size="sm"
                  @click="AddTask(structureInfo.item)"
                >
                  <b-icon-plus-circle /> {{ $t("buttons.AddTask") }}
                </b-button>
              </b-collapse>
            </div>
          </template>
        </b-table>
        <div class="mx-auto my-2" style="width: 140px">
          <b-button
            v-if="editMode"
            size="sm"
            @click="AddStructure(main.Structures)"
            variant="primary"
          >
            <b-icon-plus-circle /> {{ $t("buttons.AddCategory") }}
          </b-button>
        </div>
      </b-card-body>
    </b-tab>
  </div>
</template>
<script>
export default {
  props: {
    resources: {
      required: true,
      type: Array,
    },
    mainCategories: {
      required: true,
      type: Array,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
    external: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    // Panel,
  },
  watch: {
    editMode() {},
    external() {},
  },
  methods: {
    AddResource(taskInfo) {
      if (taskInfo.Resources.length < this.resources.length) {
        let newResource = {
          Id: "TaskResource_" + String(Date.now()),
          Hours: 0,
          Resource: {
            Id: "..",
            Name: "",
            HourlyRate: 0,
            ExternalRate: 0,
          },
          Cost: 0,
          ExternalCost: 0,
          FinalCost: 0,
          FinalExternalCost: 0,
        };
        this.$emit("additem", taskInfo.Resources, newResource);
      } else {
        this.$emit(
          "showmessage",
          this.$t("messages.titles.warning"),
          this.$t("messages.MaxTaskResources")
        );
      }
    },
    DeleteResource(resource, taskInfo) {
      const index = taskInfo.Resources.indexOf(resource);
      if (index > -1) {
        taskInfo.Resources.splice(index, 1);
      }
    },
    InsertTask(structureInfo, index, ex) {
      let newTask = {
        Id: "Task_" + String(Date.now()),
        Description: "",
        Resources: [],
        Cost: 0,
        ExternalCost: 0,
        Hours: 0,
        Parameters: [],
        _showDetails: true,
      };
      console.log(ex);
      this.$emit("insertitem", structureInfo.Tasks, newTask, index);
    },
    AddTask(structureInfo) {
      let newTask = {
        Id: "Task_" + String(Date.now()),
        Description: "",
        Resources: [],
        Cost: 0,
        ExternalCost: 0,
        Hours: 0,
        Parameters: [],
        _showDetails: true,
      };
      this.$emit("additem", structureInfo.Tasks, newTask);
    },
    DeleteTask(task, structureInfo) {
      const index = structureInfo.Tasks.indexOf(task);
      if (index > -1) {
        structureInfo.Tasks.splice(index, 1);
      }
    },
    AddStructure(structures) {
      var structure = {
        Id: "Structure_" + String(Date.now()),
        Cost: 0,
        ExternalCost: 0,
        Name: "",
        Tasks: [],
        _showDetails: true,
        Comment: "",
      };
      this.$emit("additem", structures, structure);
    },
    DeleteStructure(structure, structures) {
      const index = structures.indexOf(structure);
      if (index > -1) {
        structures.splice(index, 1);
      }
    },
    selectParameters(item) {
      this.$emit("selectedItem", item);
    },
    sumParameterValues(paramArray) {
      let paramValuesAccumulate = paramArray.reduce(
        (accumulate, parameter) => accumulate * parameter.value,
        1
      );
      return paramValuesAccumulate;
    },
  },
  computed: {
    fields() {
      return {
        MainCategories: [
          {
            key: "Name",
            label: this.$t("fields.MainCategories.Category"),
          },
          {
            key: this.external ? "ExternalCost" : "Cost",
            label: "€",
          },
        ],
        TaskCategories: [
          {
            key: "Description",
            label: this.$t("fields.TaskCategories.Task"),
          },
          {
            key: "Hours",
            label: this.$t("fields.TaskCategories.Hours"),
          },
          {
            key: "Parameters",
            label: this.$t("fields.TaskCategories.Parameters"),
          },
          {
            key: this.external ? "ExternalCost" : "Cost",
            label: "€",
          },
        ],
        Resources: [
          {
            key: "Name",
            label: this.$t("fields.TaskCategories.Resource"),
          },
          {
            key: "Hours",
            label: this.$t("fields.TaskCategories.Hours"),
          },
          {
            key: this.external ? "ExternalCost" : "Cost",
            label: this.$t("fields.TaskCategories.PerPatient"),
          },
          {
            key: this.external ? "FinalExternalCost" : "FinalCost",
            label: "€",
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss">
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.custom-border > td {
  border-top: 4px solid;
}
</style>
