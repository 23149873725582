<template>
  <div>
    <b-tab v-for="cost in costCategories" :key="cost.Name" :title="cost.Name">
      <template #title>
        <span class="d-inline-block text-truncate" style="max-width: 150px">
          {{ cost.Name }}
        </span>
      </template>
      <b-card-body class="p-0">
        <h3>{{ cost.Name }}</h3>
        <h4>
          {{ "Cost: "
          }}<i18n-n
            :value="external ? cost.ExternalCost : cost.Cost"
            format="currency"
          />
        </h4>
        <b-table
          :fields="fields"
          :items="cost.Items"
          outlined
          small
          select-mode="single"
          show-empty
          :emptyText="$t('placeholders.NoInformationAdded')"
        >
          <template #head()="data">
            <div class="input text-left">
              {{ data.label }}
            </div>
          </template>
          <template v-if="editMode" v-slot:cell(Name)="row">
            <b-input
              :disabled="row.item.Blocked || row.item.IsMaterialCost"
              v-model="row.item.Name"
            />
          </template>
          <template v-slot:cell(Cost)="row">
            <b-row v-if="editMode && !external">
              <b-col>
                <currency-input
                  class="form-control text-left mr-sm-2"
                  v-model="row.item.Cost"
                  currency="EUR"
                  min="0"
                  step="0.01"
                  max="9999999"
                  :disabled="row.item.IsMaterialCost"
                  :locale="$i18n.locale"
                  @change="RecalculateCostItem(row.item, cost)"
                />
              </b-col>
              <b-col cols="3">
                <div class="input text-left">
                  <i18n-n
                    :value="row.item.FinalCost || row.item.Cost"
                    format="currency"
                  />
                </div>
              </b-col>
              <b-col cols="1">
                <b-button
                  class="rightBlock"
                  size="sm"
                  @click="SelectParametersItem(row.item)"
                  variant="primary"
                >
                  <b-icon-sliders />
                </b-button>
              </b-col>
              <b-col cols="1">
                <b-button
                  v-if="!row.item.Blocked"
                  class="rightBlock"
                  size="sm"
                  @click="DeleteCostItem(row.item, cost)"
                  variant="danger"
                >
                  <b-icon-dash-square />
                </b-button>
              </b-col>
            </b-row>
            <div v-else class="input text-left">
              <b-row>
                <b-col>
                  <i18n-n :value="row.value" format="currency" />
                </b-col>
                <b-col>
                  <i18n-n
                    :value="row.item.FinalCost || row.item.Cost"
                    format="currency"
                  />
                </b-col>
              </b-row>
            </div>
          </template>
          <template v-slot:cell(ExternalCost)="row">
            <b-row v-if="editMode && external">
              <b-col>
                <currency-input
                  class="form-control text-left mr-sm-2"
                  v-model="row.item.ExternalCost"
                  currency="EUR"
                  min="0"
                  step="0.01"
                  max="9999999"
                  :locale="$i18n.locale"
                  @change="RecalculateCostItem(row.item, cost)"
                />
              </b-col>
              <b-col cols="3">
                <div class="input text-left">
                  <i18n-n
                    :value="row.item.FinalExternalCost || row.item.ExternalCost"
                    format="currency"
                  />
                </div>
              </b-col>
              <b-col cols="1">
                <b-button
                  class="rightBlock"
                  size="sm"
                  @click="SelectParametersItem(row.item)"
                  variant="primary"
                >
                  <b-icon-sliders />
                </b-button>
              </b-col>
              <b-col cols="1">
                <b-button
                  v-if="!row.item.Blocked"
                  class="rightBlock"
                  size="sm"
                  @click="DeleteCostItem(row.item, cost)"
                  variant="danger"
                >
                  <b-icon-dash-square />
                </b-button>
              </b-col>
            </b-row>
            <div v-else class="input text-left">
              <b-row>
                <b-col>
                  <i18n-n :value="row.value" format="currency" />
                </b-col>
                <b-col>
                  <i18n-n
                    :value="row.item.FinalExternalCost || row.item.ExternalCost"
                    format="currency"
                  />
                </b-col>
              </b-row>
            </div>
          </template>
        </b-table>
        <div class="mx-auto my-2" style="width: 140px">
          <b-button
            v-if="editMode"
            size="sm"
            @click="AddCostItem(cost.Items)"
            variant="primary"
          >
            <b-icon-plus-circle /> {{ $t("buttons.AddItem") }}
          </b-button>
          <material-cost-item-select-option
            v-if="editMode"
            :costId="cost.Id"
            @onItemSelected="AddMaterialCostItem(cost.Items, $event)"
          />
        </div>
      </b-card-body>
    </b-tab>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MaterialCostItemSelectOption from "@/components/cost-calculation/material-cost-item-select-option.vue";

Number.prototype.round = function (places) {
  return +(Math.round(this + "e+" + places) + "e-" + places);
};

export default {
  components: {
    MaterialCostItemSelectOption,
  },
  props: {
    costCategories: {
      required: true,
      type: Array,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
    external: {
      required: true,
      type: Boolean,
    },
    isPerPatient: {
      required: true,
      type: Boolean,
    },
    numPatients: {
      required: true,
      type: Number,
    },
    calculation: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    editMode() {},
    external() {},
    calculation() {
      for (var costCategories in this.costCategories) {
        for (var item in this.costCategories[costCategories].Items) {
          this.RecalculateCostItem(
            this.costCategories[costCategories].Items[item],
            this.costCategories[costCategories]
          );
        }
      }
    },
    numPatients() {
      for (var costCategories in this.costCategories) {
        for (var item in this.costCategories[costCategories].Items) {
          this.RecalculateCostItem(
            this.costCategories[costCategories].Items[item],
            this.costCategories[costCategories]
          );
        }
      }
    },
    isPerPatient() {
      for (var costCategories in this.costCategories) {
        for (var item in this.costCategories[costCategories].Items) {
          this.RecalculateCostItem(
            this.costCategories[costCategories].Items[item],
            this.costCategories[costCategories]
          );
        }
      }
    },
  },
  methods: {
    RecalculateCostItem(itemInfo, costCategory) {
      this.$forceUpdate();

      if (itemInfo.ExternalCost >= 0 && itemInfo.ExternalCost < itemInfo.Cost)
        itemInfo.ExternalCost = itemInfo.Cost;

      itemInfo.Cost = itemInfo.Cost.round(2);
      itemInfo.ExternalCost = itemInfo.ExternalCost.round(2);

      if (this.isPerPatient) {
        itemInfo.FinalCost = itemInfo.Cost * this.numPatients;
        itemInfo.FinalExternalCost = itemInfo.ExternalCost * this.numPatients;
      } else {
        itemInfo.FinalCost = itemInfo.Cost;
        itemInfo.FinalExternalCost = itemInfo.ExternalCost;
      }

      if (itemInfo.Parameters.length > 0) {
        itemInfo.FinalCost = itemInfo.Parameters.reduce(
          (r, b) => r * this.getParameterValue(b),
          itemInfo.FinalCost
        );
        itemInfo.FinalExternalCost = itemInfo.Parameters.reduce(
          (r, b) => r * this.getParameterValue(b),
          itemInfo.FinalExternalCost
        );
      }

      itemInfo.FinalCost = itemInfo.FinalCost.round(2);
      itemInfo.FinalExternalCost = itemInfo.FinalExternalCost.round(2);

      if (costCategory.Items.length > 1) {
        costCategory.Cost = costCategory.Items.reduce(
          (r, b) => r + (b.FinalCost ? b.FinalCost : 0),
          0
        );
        costCategory.ExternalCost = costCategory.Items.reduce(
          (r, b) => r + (b.FinalExternalCost ? b.FinalExternalCost : 0),
          0
        );
      } else {
        costCategory.Cost = costCategory.Items[0].FinalCost;
        costCategory.ExternalCost = costCategory.Items[0].FinalExternalCost;
      }

      costCategory.Cost = costCategory.Cost.round(2);
      costCategory.ExternalCost = costCategory.ExternalCost.round(2);

      this.$emit("RecalculateItem", itemInfo, costCategory);
    },
    AddMaterialCostItem(costItems, materialCostItemData) {
      let newCostItem = {
        Id:
          materialCostItemData.idMaterialCostCategory +
          "." +
          materialCostItemData.idMaterialCostClass,
        Blocked: false,
        SystemBlocked: false,
        Cost: materialCostItemData.average,
        ExternalCost: materialCostItemData.average,
        Name: materialCostItemData.name,
        FinalCost: materialCostItemData.average,
        FinalExternalCost: materialCostItemData.average,
        Parameters: [],
        IsMaterialCost: true,
        IdMaterialCostCategory: materialCostItemData.idMaterialCostCategory,
        IdMaterialCostClass: materialCostItemData.idMaterialCostClass,
      };
      this.$emit("additem", costItems, newCostItem);
      this.RecalculateCostItem(newCostItem, costItems);
    },
    AddCostItem(costItems) {
      let newCostItem = {
        Id: "CostItem_" + String(Date.now()),
        Cost: 0,
        ExternalCost: 0,
        Name: "",
        FinalCost: 0,
        FinalExternalCost: 0,
        Parameters: [],
      };
      this.$emit("additem", costItems, newCostItem);
    },
    DeleteCostItem(cost, costCategory) {
      const index = costCategory.Items.indexOf(cost);
      if (index > -1) {
        costCategory.Items.splice(index, 1);
      }

      if (costCategory.Items.length == 0) {
        costCategory.Cost = 0;
        costCategory.ExternalCost = 0;
      } else if (costCategory.Items.length > 1) {
        costCategory.Cost = costCategory.Items.reduce(
          (r, b) => r + (b.FinalCost ? b.FinalCost : 0),
          0
        );
        costCategory.ExternalCost = costCategory.Items.reduce(
          (r, b) => r + (b.FinalExternalCost ? b.FinalExternalCost : 0),
          0
        );
      } else {
        costCategory.Cost = costCategory.Items[0].FinalCost;
        costCategory.ExternalCost = costCategory.Items[0].FinalExternalCost;
      }

      this.$emit("RecalculateItem", costCategory);
    },
    getParameterValue(parameter) {
      if (parameter.idParameter == 0)
        return parameter.value ? parameter.value : 1;
      const param = this.parameters.find(
        (x) => x.idParameter == parameter.idParameter
      );
      if (param) return param.value ? param.value : 1;

      return 1;
    },
    SelectParametersItem(item) {
      this.$emit("selectedItem", item);
    },
  },
  computed: {
    ...mapGetters("Subcases", ["parameters"]),
    fields() {
      return [
        {
          key: "Name",
          sortable: true,
          label: this.$t("fields.CostCategories.Item"),
        },
        {
          key: this.external ? "ExternalCost" : "Cost",
          sortable: true,
          label: "€",
        },
        {
          key: " ",
          label: " ",
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
