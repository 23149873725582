<template>
  <b-modal id="state-modal" ref="state-modal" title="Warning" @ok="handleOk">
    <div>
      <p v-if="state == 3">The case {{ name }} is being edited right now.</p>
      <p v-if="state == 2">
        The case for subcase {{ name }} has already been closed.
      </p>
    </div>
    <template v-slot:modal-footer>
      <b-button
        v-if="state == 3"
        variant="warning"
        class="float-right"
        @click="handleEditAnyways"
        >Edit</b-button
      >
      <b-button variant="secondary" class="float-right" @click="handleOk"
        >OK</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    info: {
      type: Object,
      required: true,
    },
    state: {
      type: Number,
      required: true,
    },
    idSubcase: {
      type: [Number, String],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  watch: {
    show() {
      this.$refs["state-modal"].show();
    },
  },
  methods: {
    handleOk() {
      this.$refs["state-modal"].hide();
    },
    handleReopen() {
      this.$api
        .ChangeState(this.idSubcase, 2)
        .then(() => {
          this.$emit("edit");

          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs["state-modal"].hide();
          });
        })
        .catch((error) => {
          console.log("Change State Error: ", error);
          this.handleOk();
        });
    },
    handleEditAnyways() {
      this.$api
        .PutSubcaseState(this.idSubcase, 3)
        .then(() => {
          this.$emit("edit");
        })
        .catch((error) => {
          console.log("Change State Error: ", error);
        });

      this.handleOk();
    },
  },
  computed: {
    ...mapGetters("Cases", ["isAdmin"]),
  },
};
</script>

<style></style>
