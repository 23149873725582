<template>
  <b-tab key="parameters" :title="$t('objects.parameters')">
    <template #title>
      <span class="d-inline-block text-truncate" style="max-width: 150px">
        {{ $t("objects.parameters") }}
      </span>
    </template>
    <b-card-body class="p-0">
      <b-table
        :fields="fields"
        :items="parameters"
        outlined
        small
        show-empty
        :emptyText="$t('placeholders.NoInformationAdded')"
      >
        <template v-slot:cell(value)="row" v-if="editMode">
          <b-form-input
            :id="'param-value-' + row.item.idParameter"
            :placeholder="$t('views.parameters.enterParameterValue')"
            type="number"
            v-model="row.item.value"
            min="0"
            :number="true"
            required
            @change="changedValue"
          ></b-form-input>
        </template>
      </b-table>
    </b-card-body>
  </b-tab>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    editMode: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    changedValue() {
      this.$emit("changedValue");
    },
  },
  watch: {
    editMode() {},
  },
  computed: {
    ...mapGetters("Subcases", ["parameters"]),
    fields() {
      return [
        {
          key: "idParameter",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Id"),
        },
        {
          key: "parameterName",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Name"),
        },
        {
          key: "value",
          sortable: false,
          label: this.$t("fields.ParameterPanel.Value"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

columnSize {
  max-width: 150px;
}
</style>
