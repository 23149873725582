<template>
  <div>
    <b-tab v-for="time in timeCategories" :key="time.Name" :title="time.Name">
      <template #title>
        <span class="d-inline-block text-truncate" style="max-width: 150px">
          {{ time.Name }}
        </span>
      </template>
      <b-card-body class="p-0">
        <h3>{{ time.Name }}</h3>
        <b-table
          :fields="fields"
          :items="time.Items"
          outlined
          small
          select-mode="single"
          show-empty
          :emptyText="$t('placeholders.NoInformationAdded')"
        >
          <template #head()="data">
            <div class="input text-left">
              {{ data.label }}
            </div>
          </template>
          <template v-if="editMode" v-slot:cell(Name)="row">
            <b-input :disabled="row.item.Blocked" v-model="row.item.Name" />
          </template>
          <template v-if="editMode" v-slot:cell(Months)="row">
            <b-row>
              <b-col>
                <b-input class="mr-sm-2" v-model="row.item.Months" />
              </b-col>
              <b-col cols="1">
                <b-button
                  class="rightBlock"
                  v-if="!row.item.Blocked"
                  size="sm"
                  @click="DeleteTimeItem(row.item, time)"
                  variant="danger"
                >
                  <b-icon-dash-square />
                </b-button>
              </b-col>
            </b-row>
          </template> </b-table
      ></b-card-body>
    </b-tab>
    <b-tab title="content" v-if="false">
      <Panel
        v-for="(time, index) in timeCategories"
        v-bind:key="time.Name"
        :id="'time' + index"
        :title="time"
        :editable="false"
        :general="false"
        @blocked="time.Blocked = $event"
        @renamed="time.Name = $event"
      >
        <b-table
          :fields="fields"
          :items="time.Items"
          outlined
          small
          select-mode="single"
          show-empty
          :emptyText="$t('placeholders.NoInformationAdded')"
        >
          <template v-if="editMode" v-slot:cell(Name)="row">
            <b-input :disabled="row.item.Blocked" v-model="row.item.Name" />
          </template>
          <template v-if="editMode" v-slot:cell(Months)="row">
            <b-row>
              <b-col>
                <b-input class="mr-sm-2" v-model="row.item.Months" />
              </b-col>
              <b-col cols="1">
                <b-button
                  class="rightBlock"
                  v-if="!row.item.Blocked"
                  size="sm"
                  @click="DeleteTimeItem(row.item, time)"
                  variant="danger"
                >
                  <b-icon-dash-square />
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-table>
        <div class="mx-auto my-2" style="width: 140px">
          <b-button
            v-if="editMode"
            size="sm"
            @click="AddTimeItem(time.Items)"
            variant="primary"
          >
            <b-icon-plus-circle /> {{ $t("buttons.AddItem") }}
          </b-button>
        </div>
      </Panel>
    </b-tab>
  </div>
</template>
<script>
import Panel from "@/components/panel";
export default {
  props: {
    timeCategories: {
      required: true,
      type: Array,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    Panel,
  },
  watch: {
    editMode() {},
  },
  methods: {
    AddTimeItem(timeItems) {
      let newTimeItem = {
        Id: "TimeItem_" + String(Date.now()),
        Name: "",
        Months: "",
      };
      this.$emit("additem", timeItems, newTimeItem);
    },
    DeleteTimeItem(time, items) {
      const index = items.Items.indexOf(time);
      if (index > -1) {
        items.Items.splice(index, 1);
      }
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "Name",
          sortable: true,
          label: this.$t("fields.TimeCategories.Name"),
        },
        {
          key: "Months",
          sortable: true,
          label: this.$t("fields.TimeCategories.Description"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBlock {
  float: right;
}

.leftBlock {
  float: left;
}

.datalist {
  display: none;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
