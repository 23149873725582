var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"select-parameter",attrs:{"id":"select-parameter-modal","title":_vm.$t('views.parameters.title')},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_vm._t("footer",function(){return [_c('b-button',{attrs:{"disabled":_vm.okLoading},on:{"click":function($event){return _vm.$bvModal.hide('select-parameter-modal')}}},[_vm._v(_vm._s(_vm.$t("general.cancel")))]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.okLoading},on:{"click":function($event){return _vm.$bvModal.hide('select-parameter-modal')}}},[_vm._v(_vm._s(_vm.$t("general.ok")))])]})]},proxy:true}],null,true)},[_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.values,"outlined":"","small":"","show-empty":"","emptyText":_vm.$t('placeholders.NoInformationAdded')},scopedSlots:_vm._u([{key:"cell(value)",fn:function(row){return [_c('b-row',[_c('b-col',[(
                row.item.idParameter == 0 &&
                _vm.item.Parameters.some(
                  (x) => x.idParameter == row.item.idParameter
                )
              )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.item.value),expression:"row.item.value"}],staticClass:"rightBlock",attrs:{"type":"number","min":"1","step":"0.1","max":"9999999","number":true,"locale":_vm.$i18n.locale},domProps:{"value":(row.item.value)},on:{"change":function($event){return _vm.changedValue(true, row.item)},"input":function($event){if($event.target.composing)return;_vm.$set(row.item, "value", $event.target.value)}}}):_vm._e()]),_c('b-col',[_c('b-checkbox',{staticClass:"rightBlock",attrs:{"checked":_vm.item.Parameters.some(
                  (x) => x.idParameter == row.item.idParameter
                ),"block":"","size":"sm"},on:{"change":function($event){return _vm.changedValue($event, row.item)}}})],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }